<template>
  <div class="rejected" v-loading="loading">
    <Search
      :formData="formData"
      placeholderText="联盟名称/联系人/联系电话"
      @handleQuery="handleQuery"
    >
      <template slot="left">
        <SelectArea
          checkStrictly
          size="small"
          class="area"
          placeholder="地区筛选"
          @change="areaChange"
        />
        <el-select
          size="small"
          class="m-l-8"
          v-model="formData.status"
          placeholder="状态筛选"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template slot="right">
        <el-button plain size="small" @click="joinAlliance">
          加入联盟
        </el-button>
        <el-button type="primary" size="small" @click="createAlliance">
          创建联盟
        </el-button>
      </template>
    </Search>

    <el-table
      ref="table"
      class="table-base mt-8"
      size="small"
      :data="tableData"
      stripe
      border
      :default-sort="{ prop: 'auditAt', order: 'descending' }"
      style="width: 100%;min-height:400px;"
      height="400px"
      @sort-change="sortChange"
    >
      <el-table-column
        label="联盟名称"
        prop="allianceName"
        min-width="150"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="成员数"
        prop="memberCount"
        min-width="70"
        align="center"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="操作人"
        prop="contacts"
        min-width="100"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.apply.name || "-" }}
          </div>
          <div>
            {{ scope.row.apply.contacts || "-" }}/{{
              scope.row.apply.phone || "-"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="成员公司"
        prop="memberName"
        min-width="120"
        align="center"
        show-overflow-tooltip
      >
       <template slot-scope="scope">
          <div>
            {{ scope.row.member.name || "-" }}
          </div>
          <div>
            {{ scope.row.member.contacts || "-" }}/{{
              scope.row.member.phone || "-"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="成员地区"
        prop="area"
        min-width="120"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row.apply.area || "-" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        prop="statusName"
        min-width="60"
        align="center"
        show-overflow-tooltip
      ></el-table-column
      ><el-table-column
        label="备注"
        prop="remark"
        min-width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="审核时间"
        prop="auditAt"
        min-width="100"
        align="center"
        sortable
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>
            {{ $dayjs(scope.row.auditAt).format("YYYY-MM-DD HH:mm:ss") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            style="margin-right: 16px"
            :disabled="!scope.row.action"
            @click="handleReapply(scope.row)"
          >
            再次申请
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="table-bottom"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      :current-page="pageData.page"
      :page-sizes="pageData.pageSizes"
      :page-size="pageData.pageSize"
      :pager-count="pageData.pagerCount"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageData.total"
    >
    </el-pagination>

    <!-- 加入联盟 -->
    <JoinAllianceBox ref="joinAlliance" />
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import JoinAllianceBox from "../../components/JoinAllianceBox.vue";
import {
  getAllianceFailedList, //已拒绝/已驳回列表
  applyAlliance, //申请加盟
  inviteAlliance, //邀请加盟
} from "@/api/alliance";

const defaultFormData = {
  keyword: "", // 关键字
  areaId: 0,
};
export default {
  name: "Rejected",
  components: {
    Search,
    SelectArea,
    JoinAllianceBox,
  },
  data() {
    return {
      loading: false,
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      tableData: [],
      pageData: {
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        pagerCount: 5,
      },
      sort: "auditAt-desc",
      statusOptions: [
        {
          value: "reject",
          label: "已驳回",
        },
        {
          value: "refuse",
          label: "已拒绝",
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    //再次申请、邀请
    handleReapply(row) {
      if (!row.action) return;
      let params = {
        id: row.allianceId,
      };
      if (row.status == "refuse" && row.action == "invite") {
        params.companyIds = [row.memberId];
        inviteAlliance(params).then(() => {
          this.$message.success("邀请成功");
        });
      } else if (row.status == "reject" && row.action == "apply") {
        applyAlliance(params).then(() => {
          this.$message.success("申请成功");
        });
      }
    },
    //审核确定刷新列表
    // handleSure() {
    //   this.getList();
    // },
    handleQuery() {
      this.pageData.page = 1;
      this.getList();
    },
    areaChange(areaObj) {
      this.formData.areaId = areaObj.areaId;
      this.handleQuery();
    },
    sortChange(sort) {
      if (!sort.order) return;
      let order = "";
      if (sort.order === "descending") {
        order = "desc";
      }
      if (sort.order === "ascending") {
        order = "asc";
      }
      this.sort = sort.prop + "-" + order;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageData.page = 1;
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.formData,
        page: this.pageData.page,
        pageSize: this.pageData.pageSize,
        sort: this.sort,
      };
      getAllianceFailedList(params).then((res) => {
        this.tableData = res?.rows || [];
        this.pageData.total = res?.totalSize || 0;
        this.loading = false;
      });
    },
    //加入联盟
    joinAlliance() {
      this.$refs.joinAlliance.show();
    },
    //创建联盟
    createAlliance() {
      this.$router.push("/allianceManage/create");
    },
  },
};
</script>

<style lang="less" scoped>
.rejected {
  height: 100%;
  display: flex;
  flex-direction: column;
  .area {
    width: 200px !important;
  }
  .table-bottom {
    text-align: right;
    margin-top: @margin-size-secondary;
  }
}
</style>
