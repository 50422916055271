<template>
  <div class="alliance">
    <div class="head">联盟管理</div>
    <el-tabs type="card" v-model="activeTab" @tab-click="changeTab">
      <el-tab-pane
        v-for="(tab, index) in tabs"
        :key="index"
        :label="tab.name"
        :name="tab.id"
      >
        <components
          v-if="activeTab === tab.id"
          :is="tab.id"
          @tabChange="handlerTabChange"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MyAlliances from "./myAlliances/index.vue";
import TobeChecked from "./tobeChecked/index.vue";
import Rejected from "./rejected/index.vue";
import { TabChangeMixin } from "@/mixins/tabChangeMixin.js";
import {
  getAllianceCounts, //联盟相关统计信息
} from "@/api/alliance";
import eventBus from "@/event";

export default {
  mixins: [TabChangeMixin],
  components: {
    MyAlliances,
    TobeChecked,
    Rejected,
  },
  data() {
    return {
      activeTab: "MyAlliances",
      defaultTab: "MyAlliances",
      tabCountObj: {},
      // tabs: [
      //   { id: "MyAlliances", name: "我的联盟" },
      //   { id: "TobeChecked", name: "待审核" },
      //   { id: "Rejected", name: "已拒绝/已驳回" },
      // ],
    };
  },
  computed: {
    tabs() {
      return [
        {
          id: "MyAlliances",
          name: `我的联盟（${this.tabCountObj.myAllianceCount || 0}）`,
        },
        {
          id: "TobeChecked",
          name: `待审核（${this.tabCountObj.toAuditCount || 0}）`,
        },
        { id: "Rejected", name: "已拒绝/已驳回" },
      ];
    },
  },
  created() {
    this.getAllianceCounts();
  },
  mounted() {
    eventBus.$on("reGetCounts", (val) => {
      console.log("reGetCounts", val);
      this.getAllianceCounts();
    });
  },
  destroyed() {
    eventBus.$off("reGetCounts");
  },
  methods: {
    getAllianceCounts() {
      getAllianceCounts().then((res) => {
        this.tabCountObj = res || {};
      });
    },
    handlerTabChange(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style lang="less" scoped>
.alliance {
  height: 100%;
  overflow: auto;
  .head {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background: #eeeeee;
    border-bottom: 1px solid #e9e9e9;
  }
  .el-tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /deep/ .el-tabs__header {
      margin: 0;
    }
    /deep/ .el-tabs__content {
      flex: 1;
      padding: @padding-size-main;
      .el-tab-pane {
        height: 100%;
        min-height: 400px;
      }
    }
  }
}
</style>
