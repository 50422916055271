<template>
  <el-dialog
    title="申请加盟"
    class="applyJoin"
    :visible.sync="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    top="5vh"
    width="60%"
  >
    <div class="content">
      <AllianceInfo type="applyBox" :allianceInfo="allianceInfo" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSumbit"
        >同意并提交</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import AllianceInfo from "./AllianceInfo.vue";
import {
  getAllianceDetail, //联盟详情
  applyAlliance, //申请加盟
} from "@/api/alliance";
export default {
  name: "ApplyJoinBox",
  components: {
    AllianceInfo,
  },
  props: {
    allianceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dialogVisible: false,
      allianceInfo: {},
    };
  },
  methods: {
    handleSumbit() {
      let params = {
        id: this.allianceId,
      };
      applyAlliance(params).then(() => {
        this.$message.success("申请成功");
        this.close();
        this.$emit("refresh", true);
      });
    },
    show() {
      this.dialogVisible = true;
      setTimeout(() => {
        this.getAllianceDetail();
      }, 200);
    },
    getAllianceDetail() {
      getAllianceDetail({ id: this.allianceId }).then((res) => {
        this.allianceInfo = res || {};
      });
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.applyJoin {
  // .content {
  //   display: flex;
  //   flex-direction: column;
  // }
  /deep/.w-e-text {
    max-height: 450px;
  }
  /deep/.el-dialog__footer {
    text-align: right;
  }
}
</style>
