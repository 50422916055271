<template>
  <el-dialog
    title="审核"
    class="audit"
    :visible.sync="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    width="30%"
  >
    <div class="content">
      <el-form
        :model="ruleForm"
        :show-message="false"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="审核类型：" prop="status" required>
          <el-radio-group v-model="ruleForm.status">
            <el-radio :label="true">同意</el-radio>
            <el-radio :label="false">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="审核备注："
          prop="remark"
        >
          <el-input
            type="textarea"
            v-model="ruleForm.remark"
            maxlength="200"
            :autosize="{ minRows: 4, maxRows: 4 }"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSumbit">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  applyAllianceAudit, //加盟申请审核
  inviteAllianceAudit, //审核加盟邀请
} from "@/api/alliance";

export default {
  name: "AuditBox",
  props: {
    curRow: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        status: true,
      },
    };
  },
  methods: {
    //确定
    handleSumbit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.reqSumbit();
        } else {
          // this.$message.warning("请填写审核备注");
          return false;
        }
      });
    },
    reqSumbit() {
      let params = {};
      let tips = "审核通过";
      if (this.curRow.status == "apply") {
        if (this.ruleForm.status) {
          params = {
            status: "normal",
            remark: this.ruleForm.remark,
          };
        } else {
          params = {
            status: "reject",
            remark: this.ruleForm.remark,
          };
          tips = "驳回申请";
        }
        let obj = {
          id: this.curRow.id,
          params,
        };
        applyAllianceAudit(obj).then(() => {
          this.$message.success(tips + "成功");
          this.close();
          this.$emit("sure", true);
        });
      }
      if (this.curRow.status == "invite") {
        if (this.ruleForm.status) {
          params = {
            status: "normal",
            remark: this.ruleForm.remark,
          };
        } else {
          params = {
            status: "refuse",
            remark: this.ruleForm.remark,
          };
          tips = "拒绝邀请";
        }
        let obj = {
          id: this.curRow.id,
          params,
        };
        inviteAllianceAudit(obj).then(() => {
          this.$message.success(tips + "成功");
          this.close();
          this.$emit("sure", true);
        });
      }
    },
    show() {
      this.dialogVisible = true;
      if (this.$refs["ruleForm"]) {
        this.resetForm();
      }
    },
    close() {
      this.dialogVisible = false;
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
.audit {
  .content {
    display: flex;
    flex-direction: column;
  }
  /deep/.el-dialog__footer {
    text-align: right;
  }
}
</style>
